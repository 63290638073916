//
// Root
//

// Light mode
@include color-mode(light) {
  // Header light
  --#{$prefix}app-header-light-separator-color: #{$app-header-light-separator-color};

  // Sidebar base
  --#{$prefix}app-sidebar-base-toggle-btn-box-shadow: #{$app-sidebar-base-toggle-btn-box-shadow};
  --#{$prefix}app-sidebar-base-toggle-btn-bg-color: #{$app-sidebar-base-toggle-btn-bg-color};
  --#{$prefix}app-sidebar-base-toggle-btn-border-color: #{$app-sidebar-base-toggle-btn-border-color};
  --#{$prefix}app-sidebar-base-border-color: #{$app-sidebar-base-border-color};

  // Sidebar light
  --#{$prefix}app-sidebar-light-bg-color: #{$app-sidebar-light-bg-color};
  --#{$prefix}app-sidebar-light-box-shadow: #{$app-sidebar-light-box-shadow};
  --#{$prefix}app-sidebar-light-separator-color: #{$app-sidebar-light-separator-color};
  --#{$prefix}app-sidebar-light-scrollbar-color-hover: #{$app-sidebar-light-scrollbar-color-hover};
  --#{$prefix}app-sidebar-light-menu-heading-color: #{$app-sidebar-light-menu-heading-color};
  --#{$prefix}app-sidebar-light-menu-link-bg-color-active: #{$app-sidebar-light-menu-link-bg-color-active};
  --#{$prefix}app-sidebar-light-header-menu-link-bg-color-active: #{$app-sidebar-light-header-menu-link-bg-color-active};
  --#{$prefix}app-sidebar-light-menu-link-color: #{$app-sidebar-light-menu-link-color};
  --#{$prefix}app-sidebar-light-menu-link-icon-color: #{$app-sidebar-light-menu-link-icon-color};
}

// Dark mode
@include color-mode(dark) {
  // Header light
  --#{$prefix}app-header-light-separator-color: #{$app-header-light-separator-color-dark};

  // Sidebar base
  --#{$prefix}app-sidebar-base-toggle-btn-box-shadow: #{$app-sidebar-base-toggle-btn-box-shadow-dark};
  --#{$prefix}app-sidebar-base-toggle-btn-bg-color: #{$app-sidebar-base-toggle-btn-bg-color-dark};
  --#{$prefix}app-sidebar-base-toggle-btn-border-color: #{$app-sidebar-base-toggle-btn-border-color-dark};
  --#{$prefix}app-sidebar-base-border-color: #{$app-sidebar-base-border-color-dark};

  // Sidebar light
  --#{$prefix}app-sidebar-light-bg-color: #{$app-sidebar-light-bg-color-dark};
  --#{$prefix}app-sidebar-light-box-shadow: #{$app-sidebar-light-box-shadow-dark};
  --#{$prefix}app-sidebar-light-separator-color: #{$app-sidebar-light-separator-color-dark};
  --#{$prefix}app-sidebar-light-scrollbar-color-hover: #{$app-sidebar-light-scrollbar-color-hover-dark};
  --#{$prefix}app-sidebar-light-menu-heading-color: #{$app-sidebar-light-menu-heading-color-dark};
  --#{$prefix}app-sidebar-light-menu-link-bg-color-active: #{$app-sidebar-light-menu-link-bg-color-active-dark};
  --#{$prefix}app-sidebar-light-header-menu-link-bg-color-active: #{$app-sidebar-light-header-menu-link-bg-color-active-dark};
  --#{$prefix}app-sidebar-light-menu-link-color: #{$app-sidebar-light-menu-link-color-dark};
  --#{$prefix}app-sidebar-light-menu-link-icon-color: #{$app-sidebar-light-menu-link-icon-color-dark};
}


.bg-custom-black {
  background-color: #301b4463!important;
}

.pc-png, .console-png {
  width: 20px!important;
  margin-right: 6px;
}

.xbox-png {
  width: 16px!important;
  margin-right: 6px;
}

.fctt-orders {
  color: whitesmoke!important;
  font-size: 48px;
  padding-top: 10px;
  background: linear-gradient(135deg, var(--bs-primary), var(--bs-info));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  font-family: "Dosis", sans-serif;
}
.fctt-orders {
  font-size: clamp(4rem, 2.8vw, 2.6rem);
  background: linear-gradient(135deg, var(--bs-primary), var(--bs-info));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  white-space: nowrap;
  font-family: "Dosis", sans-serif;
  font-weight: 600;
  line-height: 1;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  padding-left:20px;
}

.title-color{
  color: var(--bs-info) !important;
}

.logo-style {
  color: var(--bs-primary) !important;
  display: inline-block;
  font-family: "Dosis", sans-serif;
  font-weight: 600!important;
  font-size: 48px;
}

.card-titles {
  font-size: 20px;
  color: whitesmoke!important;
 
}

.cards-titulos {
  color: whitesmoke!important;
  background: linear-gradient(135deg, var(--bs-primary), var(--bs-info));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.fctt-suppliers {
  font-size: clamp(3.2rem, 2vw, 2rem);
  background: linear-gradient(135deg, var(--bs-warning), var(--bs-warning));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  white-space: nowrap;
  font-family: "Dosis", sans-serif;
  font-weight: 600;
  line-height: 1;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  padding-left:20px;
  padding-top: 25px;
}

.title-color-supplier{
  color: var(--bs-warning) !important;
}